import Layout from "@/layouts"

const settingsCategory = {
  path: "/settings-category",
  component: Layout,
  name: "settingsCategory",
  redirect: "/settings-category/index",

  children: [
    {
      path: "/settings-category/index",
      component: () => import("@/views/settingsCategory/index"),
      name: "settingsCategory.index",
      meta: { title: "settings Category" },

    },
    {
      path: "",
      component: () => import("@/views/settingsCategory/_slug"),
      name: "settingsCategory.slug",
      meta: { title: "slug" },

      children: [
        {
          path: "/settings-category/workTimes/index",
          component: () => import("@/views/workTime/index"),
          name: "workTimes", //.index
          meta: { title: "График работа" },

        },
        {
          path: "/settings-category/bunk/departments",
          component: () => import("@/views/bunk/departments"),
          name: "departments",
          meta: { title: "Отделения" },

        },
        {
          path: "/settings-category/specialties/index",
          component: () => import("@/views/specialty/index"),
          name: "specialties", //.index
          meta: { title: "Специальности" },

        },
        {
          path: "/settings-category/rooms/index",
          component: () => import("@/views/rooms/index"),
          name: "rooms", //.index
          meta: { title: "rooms" },

        },
        {
          path: "/settings-category/services/index",
          component: () => import("@/views/service/index"),
          name: "service", //.index
          meta: { title: "Услуги" },

        },
        {
          path: "/settings-category/partnerDoctor/index",
          component: () => import("@/views/partnerDoctor/index"),
          name: "partnerDoctor", //.index
          meta: { title: "Партнер доктор" },

        },
        {
          path: "/settings-category/partnerClinic/index",
          component: () => import("@/views/partnerClinic/index"),
          name: "partnerClinic", //.index
          meta: { title: "Партнер доктор" },

        },
        {
          path: "/settings-category/ownerBranch/index",
          component: () => import("@/views/ownerBranch/index"),
          name: "partnerClinic", //.index
          meta: { title: "Партнер доктор" },

        },
        {
          path: "/settings-category/couriers/index",
          component: () => import("@/views/couriers/index"),
          name: "couriers", //.index
          meta: { title: "Курьеры" },

        },
        {
          path: "/settings-category/insuranceCompany/index",
          component: () => import("@/views/insuranceCompany/index"),
          name: "insuranceCompany", //.index
          meta: { title: "Партнер доктор" },

        },
        {
          path: "/settings-category/ProductsWarehouses/index",
          component: () => import("@/views/ProductsWarehouses/index"),
          name: "productsWarehouses",
          meta: { title: "Продукты" }

        },
        {
          path: "/settings-category/stocksSryaWarehouses/index",
          component: () => import("@/views/stocksSryaWarehouses/index"),
          name: "stocksSryaWarehouses",
          meta: { title: "Склады" }

        },
        {
          path: "/settings-category/warehouseProvider/index",
          component: () => import("@/views/warehouseProvider/index"),
          name: "warehouseProvider",
          meta: { title: "Поставщики" }

        },
        {
          path: "/settings-category/currencies/index",
          component: () => import("@/views/currency/index"),
          name: "currencies", //.index
          meta: { title: "Партнер доктор" },

        },
        {
          path: "/settings-category/currenciesWarehouses/index",
          component: () => import("@/views/currenciesWarehouses/index"),
          name: "currenciesWarehouses", //.index
          meta: { title: "Тип Валюта" },

        },
        {
          path: "/settings-category/groupsWarehouses/index",
          component: () => import("@/views/groupsWarehouses/index"),
          name: "groupsWarehouses.index",
          meta: {
            title: "Тип Группы",
          },

        },
        {
          path: "/settings-category/measurementsWarehouses/index",
          component: () => import("@/views/measurement/index"),
          name: "measurementsWarehouses", //.index
          meta: { title: "Тип Группы" },

        },
        {
          path: "/settings-category/paymentTypes/index",
          component: () => import("@/views/paymentType/index"),
          name: "paymentTypes", //.index
          meta: { title: "Тип оплаты" },

        },
        {
          path: "/settings-category/ownerClinic/index",
          component: () => import("@/views/ownerClinic/index"),
          name: "ownerClinic", //.index
          meta: { title: "Партнер доктор" },

        },
        {
          path: "/settings-category/orderColumn/index",
          component: () => import("@/views/orderColumn/index"),
          name: "orderColumn", //.index
          meta: { title: "Партнер доктор" },

        },
        {
          path: '/settings-category/excelColumns/index',
          component: () => import('@/views/excelColumns/index'),
          name: 'excelColumns.index',
          meta: { title: 'Excel' }
        },
        {
          path: '/settings-category/categoryServices/index',
          component: () => import('@/views/categoryService/index'),
          name: 'categoryServices.index',
          meta: { title: 'categoryServices' }
        },
        {
          path: "/settings-category/hours/index",
          component: () => import("@/views/hour/index"),
          name: "hours", //.index
          meta: { title: "Времени" },

        },
        {
          path: "/settings-category/conditions/index",
          component: () => import("@/views/condition/index"),
          name: "conditions", //.index
          meta: { title: "Состояние" },

        },
        {
          path: "/settings-category/states/index",
          component: () => import("@/views/state/index"),
          name: "states", //.index
          meta: { title: "Страны" },

        },
        {
          path: "/settings-category/statuses/index",
          component: () => import("@/views/status/index"),
          name: "statuses", //.index
          meta: { title: "Статус" },

        },
        {
          path: "/settings-category/region/index",
          component: () => import("@/views/region/index"),
          name: "region", //.index
          meta: {
            title: "Регион",
          },

        },
        {
          path: "/settings-category/cities/index",
          component: () => import("@/views/city/index"),
          name: "cities", //.index
          meta: { title: "Города" },

        },
        {
          path: "/settings-category/district/index",
          component: () => import("@/views/district/index"),
          name: "district", //.index
          meta: {
            title: "Регион",
          },

        },
        {
          path: "/settings-category/formLogo/index",
          component: () => import("@/views/formLogo/index"),
          name: "formLogo", //.index
          meta: {
            title: "График",
          },

        },
        {
          path: "/settings-category/discountPatient/index",
          component: () => import("@/views/discountPatient/index"),
          name: "discountPatient", //.index
          meta: { title: "Города" },

        },
        {
          path: "/settings-category/express/index",
          component: () => import("@/views/express/index"),
          name: "express", //.index
          meta: {
            title: "График",
          },

        },
        {
          path: "/settings-category/process/index",
          component: () => import("@/views/process/index"),
          name: "process", //.index
          meta: {
            title: "Процесс",
          },

        },
        {
          path: "/settings-category/department/index",
          component: () => import("@/views/department/index"),
          name: "department", //.index
          meta: { title: "Города" },

        },
        {
          path: "/settings-category/material/index",
          component: () => import("@/views/material/index"),
          name: "material", //.index
          meta: {
            title: "График",
          },

        },
        {
          path: "/settings-category/sickNumber/index",
          component: () => import("@/views/sickNumber/index"),
          name: "sickNumber", //.index
          meta: {
            title: "График",
          },

        },
        {
          path: "/settings-category/doctorSign/index",
          component: () => import("@/views/doctorSign/index"),
          name: "doctorSign", //.index
          meta: {
            title: "Отчет",
          },

        },
        {
          path: "/settings-category/socials/index",
          component: () => import("@/views/social/index"),
          name: "socials", //.index
          meta: { title: "Источник" },

        },
        {
          path: "/settings-category/costs/index",
          component: () => import("@/views/cost/index"),
          name: "costs", //.index
          meta: { title: "Типы расхода" },

        },
        {
          path: "/settings-category/documentations/index",
          component: () => import("@/views/settings/documentations/index"),
          name: "documentationList",
          meta: {
            title: "Документы editor",
          },
        },
        {
          path: "/settings-category/categoryFormTemplates/index",
          component: () =>
            import("@/views/settings/categoryFormTemplates/index"),
          name: "categoryFormTemplates",
          meta: {
            title: "Документы",
          },

        },
        {
          path: "/settings-category/printAudit",
          component: () => import("@/views/printAuditable/index"),
          name: "printAudit.index",
          meta: {
            title: "Города",
          },

        },
        {
          path: "/settings-category/permissions/index",
          component: () => import("@/views/permissions/index"),
          name: "permissions.index",
          meta: {
            title: "Permissions",
          },

        },
        {
          path: "/settings-category/roles/index",
          component: () => import("@/views/roles/index"),
          name: "roles.index",
          meta: {
            title: "Roles",
          },

        },
        {
          path: "/settings-category/users/index",
          component: () => import("@/views/users/index"),
          name: "users.index",
          meta: {
            title: "Users",
          },

        },
      ],
    },
  ],
}

export default settingsCategory
