var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('aside',{staticClass:"aside",class:{ active: _vm.drawer }},[_c('ul',{staticClass:"menu-list"},_vm._l((_vm.menu),function(item,index){return _c('li',{directives:[{name:"can-or",rawName:"v-can-or",value:(item.permission),expression:"item.permission"}],key:index,staticClass:"menu-list__item",on:{"mouseenter":function($event){return _vm.handlerAction(item)}}},[(item.link === '#')?_c('span',{staticClass:"menu-list__item-link",class:{
            active: item.children.some((child) => child.link === _vm.$route.name),
          }},[_c('i',{class:item.icon}),_vm._v(" "+_vm._s(_vm.$t(item.name))+" ")]):_c('router-link',{staticClass:"menu-list__item-link",attrs:{"to":{ name: item.link }}},[_c('i',{class:item.icon}),_vm._v(" "+_vm._s(_vm.$t(item.name))+" ")])],1)}),0),_c('div',{staticClass:"sub-menu",class:{ active: _vm.drawer },on:{"mouseenter":function($event){_vm.drawer = true},"mouseleave":function($event){_vm.drawer = false}}},[_c('h2',{staticClass:"sub-menu__title"},[_vm._v(_vm._s(_vm.$t(_vm.subMenu.name)))]),_c('ul',{staticClass:"sub-menu-list"},_vm._l((_vm.subMenu.children),function(r,i){return _c('li',{directives:[{name:"can",rawName:"v-can",value:(r.permission),expression:"r.permission"}],key:i,on:{"click":function($event){_vm.drawer = false}}},[_c('router-link',{staticClass:"sub-menu-list__item",attrs:{"to":{ name: r.link }}},[_c('i',{staticClass:"fa-solid fa-arrow-right"}),_c('span',[_vm._v(" "+_vm._s(_vm.$t(r.name))+" ")])])],1)}),0)])]),_c('aside',{staticClass:"mobile-aside",class:{ active: _vm.mobileMenu }},[_c('div',{staticClass:"close-mobile-aside",on:{"click":function($event){return _vm.toggleMobile()}}},[_c('i',{staticClass:"el-icon-close"})]),_c('ul',{staticClass:"mobile-menu-list"},_vm._l((_vm.menu),function(item,index){return _c('li',{directives:[{name:"can-or",rawName:"v-can-or",value:(item.permission),expression:"item.permission"}],key:index,staticClass:"mobile-menu-list__item",on:{"click":function($event){return _vm.unwrapSubmenu($event)}}},[(item.link === '#')?_c('div',{staticClass:"submenu-wrap d-flex w-100"},[_c('div',{staticClass:"mobile-menu-list__item-link",class:{
              active: item.children.some(
                (child) => child.link === _vm.$route.name
              ),
            }},[_c('i',{class:item.icon}),_c('p',[_vm._v(_vm._s(_vm.$t(item.name)))]),_c('i',{staticClass:"el-icon-arrow-down el-icon--right drop-mobile"})]),_c('div',{staticClass:"mobile-sub-menu",class:{ active: false }},[_c('ul',{staticClass:"mobile-sub-menu-list"},_vm._l((item.children),function(r,i){return _c('li',{key:i,on:{"click":function($event){return _vm.toggleMobile()}}},[_c('router-link',{staticClass:"mobile-sub-menu-list__item",attrs:{"to":{ name: r.link }}},[_c('i',{staticClass:"fa-solid fa-arrow-right"}),_c('span',[_vm._v(" "+_vm._s(_vm.$t(r.name))+" ")])])],1)}),0)])]):_c('div',{staticClass:"w-100",on:{"click":function($event){return _vm.toggleMobile()}}},[_c('router-link',{staticClass:"mobile-menu-list__item-link",attrs:{"to":{ name: item.link }}},[_c('i',{class:item.icon}),_c('p',[_vm._v(_vm._s(_vm.$t(item.name)))])])],1)])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }