import { index, show, store, resetPassword, restore, code, update, update_bonus, destroy, inventory, doctor_list, inventory_general, free_owner_branch, setDoctorBranch } from './../../../api/users';

export const actions = {
  index({ commit }, params = {}) {
    return new Promise((resolve, reject) => {
      index(params)
        .then((res) => {
          commit('SET_LIST', res.data.data.users);
          commit('UPDATE_PAGINATION', { key: 'total', value: res.data.data.pagination.total });
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  free_owner_branch({ commit }, params = {}) {
    return new Promise((resolve, reject) => {
      free_owner_branch(params)
        .then((res) => {
          commit('SET_FREE_LIST', res.data.data);
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  show({ commit }, id) {
    return new Promise((resolve, reject) => {
      show(id)
        .then((res) => {
          commit('SET_MODEL', res.data.data);
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },

  resetPassword({ commit }, data) {
    return new Promise((resolve, reject) => {
      resetPassword(data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },

  restore({ commit }, data) {
    return new Promise((resolve, reject) => {
      restore(data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },

  code({ commit }, data) {
    return new Promise((resolve, reject) => {
      code(data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },

  setDoctorBranch({ commit }, data) {
    return new Promise((resolve, reject) => {
      setDoctorBranch(data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.log('err');
          reject(err.response.data);
        })
    })
  },

  store({ commit }, data) {
    return new Promise((resolve, reject) => {
      store(data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },

  inventory({ commit }) {
    return new Promise((resolve, reject) => {
      inventory()
        .then((res) => {
          commit('SET_INVENTORY', res.data.data.users);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },

  inventory_general({ commit }) {
    return new Promise((resolve, reject) => {
      inventory_general()
        .then((res) => {
          commit('SET_INVENTORY_GENERAL', res.data.data.users);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },

  doctor_list({ commit }, params = {}) {
    return new Promise((resolve, reject) => {
      doctor_list(params)
        .then((res) => {
          commit('SET_DOCTORLIST', res.data.data.users);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },
  update({ commit }, params) {
    return new Promise((resolve, reject) => {
      update(params)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },

  update_bonus({ commit }, params) {
    return new Promise((resolve, reject) => {
      update_bonus(params)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err.response.data);
          });
    });
  },

  empty({ commit }) {
    return new Promise((resolve, reject) => {
      commit('EMPTY_MODEL');
      resolve();
    });
  },

  destroy({ commit }, id) {
    return new Promise((resolve, reject) => {
      destroy(id)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },
  updateSort({ commit }, sort) {
    commit('SET_SORT', sort);
  },

  updateFilter({ commit }, filter) {
    commit('SET_FILTER', JSON.parse(JSON.stringify(filter)));
  },

  updateColumn({ commit }, obj) {
    commit('UPDATE_COLUMN', obj);
  },
  updatePagination({ commit }, pagination) {
    commit('UPDATE_PAGINATION', pagination);
  },
  refreshData({ commit }) {
    return new Promise((resolve, reject) => {
      commit('REFRESH');
      resolve();
    });
  },
};
