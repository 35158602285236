<template>
  <div class="chart-card">
    <div class="chart-card__header">
      <h2>{{ $t(`message.gender_patients`) }}</h2>
    </div>

    <div>
      <apexchart ref="chart" class="chart" type="donut" width="250" :options="chartOptions" :series="series"></apexchart>
    </div>

    <div class="candidates-wrap">
      <div class="candidates-wrap__item" v-for="(item, index) in candidatesInfo" :key="index">
        
        <span>{{ item.title }}</span>
        <p>
          {{ item.total | formatMoney }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { i18n } from "@/utils/i18n";
export default {
  props: ["gender"],
  data() {
    return {
      show: false,
      series: [1, 1],
      chartOptions: {
        theme: {
          mode: 'light'
        },
        chart: {
          background: 'transparent',
          width: 380,
          type: "donut",
        },
        plotOptions: {
          pie: {
            startAngle: 0,
            // endAngle: 270
          },
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: "gradient",
        },
        legend: {
          show: false,
          formatter: function (val, opts) {
            return val + " - " + opts.w.globals.series[opts.seriesIndex];
          },
        },
        labels: [],
        colors: ["#8b5cf6", "#0ea5e9", "#f59e0b", "#10b981"],
      },
      candidatesInfo: [
        {
          title: "Male Candidates",
          total: 1,
        },
        {
          title: "Female Candidates",
          total: 1,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      mode: 'theme/MODE'
    })
  },
  watch: {
    gender: function (val) {
      this.setData(val);
    },
    mode(bool) {
      this.updateChart(bool)
    }
  },
  mounted() {
    this.updateChart(this.mode)
  },
  methods: {
    setData(val) {
      this.show = false;
      this.series = [val.man, val.woman];
      this.chartOptions.labels = [];
      this.chartOptions.labels.push(i18n.t("message.men"));
      this.chartOptions.labels.push(i18n.t("message.women"));

      this.candidatesInfo = [
        {
          title: i18n.t("message.men"),
          total: val.man,
        },
        {
          title: i18n.t("message.women"),
          total: val.woman,
        },
      ];
      this.show = true;
    },
    updateChart(bool) {
      this.$refs.chart.updateOptions({
        theme: {
          mode: bool ? 'dark' : 'light'
        }
      })
    }
  },
};
</script>

<style lang="scss">
.chart-card .chart>div {
  margin: 0 auto !important;
}
</style>

<style lang="scss" scoped>
.candidates-wrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border-top: 1px solid #e5e7eb;

  // candidates-wrap__item
  &__item {
    padding: 1rem;
    text-align: center;

    &:first-child {
      border-right: 1px solid #e5e7eb;
    }

    span {
      color: #9ca3af;
      font-size: 14px;
    }

    p {
      font-weight: 500;
      font-size: 24px;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;

      span i {
        display: inline-block;
        margin-right: 4px;
      }
    }
  }
}
</style>